#points {
    margin-top: 32px;
    margin-bottom: 32px;
}

#points > h3 {
    margin: 0px;
}

#points > p {
    margin: 0px;
}

#points > button {
    margin-top: 12px;
    margin-right: 8px;
}