#edit-purchaseable form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 350px;
    max-width: 100%;
}

#edit-purchaseable form label {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: bold;
}

#edit-purchaseable form input {
    padding: 8px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

#edit-purchaseable form .actions {
    display: flex;
    gap: 32px;
    margin-top: 24px;
}

#edit-purchaseable form .actions button {
    flex-grow: 1;
}

#edit-purchaseable .clear {
    margin-left: 4px;
}

#edit-purchaseable .editing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}