.radio {
    position: relative;
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding: 8px 10px;
    line-height: 20px;
    background-color: rgba(0, 0, 0, 0.2);
}

.radio:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.radio.checked {
    background-color: var(--primary-color);
}

.radio.disabled {
    background-color: rgba(255, 255, 255, 0.05);
    cursor: not-allowed;
}

.radio.checked.disabled {
    background-color: #aaa;
}

.radio input {
    font-size: 5em;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
}