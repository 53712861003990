:root {
  --primary-color: #7823ff;
  --secondary-color: #5e1fcb;
  --background-color: #272727;
  --text-color: #fff;
  --red: #ff4d4d;
  --dark-red: #c43a3a;
  --black: #000;
  --green: #5ed868;
  --grey: #aaa;
}

body {
  margin: 0;
  padding: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
  color: var(--text-color);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
}

input {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 6px 10px;
}

button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 12px 16px;
}

button:hover {
  background-color: var(--secondary-color);
}

button:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: #aaa;
  cursor: not-allowed;
}

button.small {
  padding: 5px;
}

button.single {
  padding: 12px 16px;
}

button.secondary.single {
  padding: 1px 5px;
}

button.destructive {
  background-color: var(--red);
}

button.destructive:hover {
  background-color: var(--dark-red);
}

button.secondary {
  background-color: #00000000;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

button.secondary:hover {
  background-color: var(--primary-color);
  color: #fff;
}

button.secondary:disabled {
  color: #aaa;
  border-color: #aaa;
}

button.secondary:disabled:hover {
  background-color: #00000000;
}

button.destructive.secondary {
  background-color: #00000000;
  color: var(--red);
  border: 1px solid var(--red);
}

button.destructive.secondary:hover {
  background-color: var(--red);
  color: #fff;
}

p.primary, span.primary {
  color: var(--primary-color);
}

p.secondary, span.secondary {
  color: #b9b9b9;
}

p.positive, span.positive, td.positive {
  color: var(--green);
}

p.negative, span.negative, p.error, span.error, td.negative {
  color: var(--red);
}

p.neutral, span.neutral {
  color: var(--gray);
}

h3.small, p.small, span.small {
  font-size: 0.8em;
}

h3.x-small, p.x-small, span.x-small {
  font-size: 0.7em;
}