.shopping-cart {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.shopping-cart h4 {
    margin: 0;
}

.shopping-cart .cart table {
    border-collapse: collapse;
}

.shopping-cart .cart thead {
    background-color: rgba(255, 255, 255, 0.1);
}

.shopping-cart .cart th, .shopping-cart .cart td {
    padding: 8px;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.shopping-cart .total {
    max-width: fit-content;
}

.shopping-cart .total td:first-child {
    font-weight: bolder;
    padding-right: 16px;
}

.shopping-cart .cart-item {
    display: flex;
    align-items: center;
    gap: 16px;
}

.shopping-cart .controls button {
    margin-right: 8px;
}