.point-shop-tile {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 12px;
    border: 2px solid transparent;
}

.point-shop-tile:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.point-shop-tile h3 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1em;
}

.point-shop-tile .buy {
    display: flex;
    gap: 8px;
    margin-top: 16px;
}

.point-shop-tile .old-price {
    color: var(--red);
    text-decoration: line-through;
}

.point-shop-tile.highlight {
    border: 2px solid var(--primary-color);
}
