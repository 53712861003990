.radio-buttons {
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    overflow-x: auto;
    width: fit-content;
    max-width: 100%;
}

.radio-buttons.disabled {
    border-color: #aaa;
}