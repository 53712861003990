#purchaseables > .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    max-width: 1000px;
    gap: 20px;
    max-height: 500px;
    overflow-y: auto;
    margin-top: 20px;
}

#purchaseables .header > button {
    margin-right: 8px;
}

#purchaseables .header .purchase {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 12px;
    margin-bottom: 16px;
    gap: 8px;
}

#purchaseables .header .purchase button {
    margin: 0;
}

#purchaseables .purchaseable.add {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5em;
}