.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 32px;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-holder {
    background: #272727;
    color: #fff;
    padding: 24px;
    border-radius: 8px;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
}