#plug .modal .header {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
}

#plug .modal h3 {
    margin: 0;
}

#plug .label {
    margin-bottom: 8px;
}

#plug .plug-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}