.purchaseable {
    display: flex;
    flex-direction: column;
    background:rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border: 1.5px solid transparent;
    cursor: pointer;
}

.purchaseable.limited {
    border: 1.5px solid var(--red);
}

.purchaseable.selected {
    border: 1.5px solid var(--green);
}

.purchaseable.unaffordable span {
    filter: grayscale(90%);
}

.purchaseable:not(.unaffordable):hover {
    background: rgba(0, 0, 0, 0.3);
}

.purchaseable .header .timer {
    color: var(--red);
    font-weight: bold;
    margin: 0;
    margin-bottom: 8px;
}

.purchaseable .header .title {
    display: flex;
    align-items: center;
    flex-grow: 0;
}

.purchaseable .emoji {
    font-size: 1.8em;
    flex-grow: 0;
    margin-right: 8px;
}

.purchaseable .description {
    color: #aaa;
    margin: 0;
    flex-grow: 1;
}

.purchaseable .name {
    font-size: 1.2em;
    font-weight: bold;
    flex-grow: 1;
}

.purchaseable .price {
    font-size: 1em;
    margin-top: 6px;
    margin-bottom: 8px;
}

.purchaseable .footer {
    margin: 0;
    margin-top: 8px;
}

.purchaseable .decrement {
    padding: 4px 8px;
    margin-left: 8px;
}

.purchaseable .count {
    margin-top: 8px;
    margin-bottom: 0;
}