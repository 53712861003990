#buttplug-controller .input {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

#buttplug-controller .input input {
    flex-grow: 1;
}

#buttplug-controller .controls {
    display: flex;
    flex-direction: row;
    gap: 16px;
}