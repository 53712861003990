#point-shop .bundles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 12px;
    max-width: 680px;
    max-height: 680px;
    overflow-y: auto;
}

#point-shop .pay-with {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;
}

#point-shop .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
