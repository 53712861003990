#managepoints {
    width: fit-content;
    padding: 24px 32px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

#managepoints p {
    margin: 0;
    margin-bottom: 8px;
}

#managepoints input {
    display: block;
    margin-bottom: 8px;
}

#managepoints > div {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
}