#create > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
}

#create > form > label {
    margin-top: 8px;
    margin-bottom: 4px;
}

#create > form > button {
    margin-top: 32px;
}