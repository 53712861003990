#logs {
    max-width: 100%;
    overflow-x: auto;
}

#logs table {
    max-width: 100%;
    table-layout: auto;
    border-collapse: collapse;
}

#logs table th {
    text-align: left;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}

#logs table td {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    min-width: fit-content;
}

#logs table td:first-child {
    min-width: 160px;
}

#logs table td:nth-child(2) {
    min-width: 100px;
}

#logs table td:nth-child(3) {
    min-width: 110px;
}

#logs table td:nth-child(4) {
    min-width: 250px;
}

#logs .controls {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 250px;
}