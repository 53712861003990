#urgent-purchaseable .modal-holder {
    background-color: transparent;
}

#urgent-purchaseable .holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}

#urgent-purchaseable .purchaseable {
    border: 5px solid var(--red);
    cursor: pointer;
    
    animation: pulse 1s infinite, shake-gentle 1s infinite;

    min-width: 250px;
    background-color: #0e0000;
}

#urgent-purchaseable .lastChance {
    color: var(--red);
    font-size: 2em;
    font-weight: bold;
}

#urgent-purchaseable p {
    margin: 10px;
}

@keyframes pulse {
    0% {
        border-color: var(--red);
    }
    50% {
        border-color: var(--black);
    }
    100% {
        border-color: var(--red);
    }
}

@keyframes shake-gentle {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(2deg);
    }
    20% {
        transform: rotate(-2deg);
    }
    30% {
        transform: rotate(2deg);
    }
    40% {
        transform: rotate(-2deg);
    }
    50% {
        transform: rotate(2deg);
    }
    60% {
        transform: rotate(-2deg);
    }
    70% {
        transform: rotate(2deg);
    }
    80% {
        transform: rotate(-2deg);
    }
    90% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0deg);
    }

}